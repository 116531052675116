/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { string as propTypeString, bool as propTypeBool } from 'prop-types';
import {
  params, string, arrayOf, extend, shape, bool
} from '@thd-nucleus/data-sources';

import { useBundleProducts } from '../hooks/useBundleProducts';

const dataModel = extend(
  {
    product: params({ itemId: string().isRequired() }).shape({
      itemId: string(),
      bundleSpecificationDetails: shape({
        type: string(),
        experienceType: string(),
        components: arrayOf(shape({
          id: string(),
          defaultProductId: string(),
          quantity: string(),
          type: string(),
          allowSwap: bool(),
          allowRemove: bool()
        }))
      })
    })
  },
  {
    products: params({ itemIds: arrayOf(string().isRequired()).isRequired() }).arrayOf({
      itemId: string()
    })
  }
);

export const withBundleProducts = (BaseComponent) => {

  const BundleComponent = ({ bundleId, storeId, listenToProductsChange, ...rest }) => {
    const bundleProducts = useBundleProducts(bundleId, storeId, listenToProductsChange);

    return (
      <BaseComponent
        {...bundleProducts}
        {...rest}
      />
    );
  };

  BundleComponent.dataModel = BaseComponent?.dataModel
    ? extend(dataModel, BaseComponent?.dataModel)
    : dataModel;

  BundleComponent.propTypes = {
    bundleId: propTypeString.isRequired,
    storeId: propTypeString.isRequired,
    listenToProductsChange: propTypeBool
  };

  BundleComponent.defaultProps = {
    listenToProductsChange: true
  };

  return BundleComponent;
};