import { useState, useEffect, useRef } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import { triggerLifeCycleEvent, getProductIndex, onLifeCycleEvent } from '../utils/product-bundle-utils';
import { getUpdatedProduct, getUpdatedProducts } from '../utils/bundle-features-utils';
import { PRODUCTS_CHANGED } from '../constants';

export const useBundleProducts = (bundleId, storeId, listenToProductsChange = true) => {
  const [productIds, setProductIds] = useState(null);
  const [productsState, setProductsState] = useState(null);
  const productsRef = useRef([]);
  const updateProductRef = useRef(null);
  const swapProducts = (prevProductId, newProductId) => {
    const i = getProductIndex(prevProductId, productsRef.current);

    if (i !== -1) {
      updateProductRef.current = {
        index: i,
        product: productsRef.current[i]
      };
      productsRef.current[i] = {
        itemId: newProductId,
        swappedProduct: updateProductRef.current.product
      };
      setProductIds([newProductId]);
    }
  };

  const swapBack = (productId) => {
    const i = getProductIndex(productId, productsRef.current);
    const product = productsRef.current[i];

    if (product?.swappedProduct) {
      const { swappedProduct, ...newSwappedProduct } = product;
      const newProduct = {
        ...swappedProduct,
        swappedProduct: newSwappedProduct
      };

      let newProducts = [...productsRef.current];
      newProducts[i] = newProduct;
      productsRef.current = newProducts;
      setProductsState(newProducts);
      triggerLifeCycleEvent(PRODUCTS_CHANGED, newProducts);
    }
  };

  const clearSwap = (productId) => {
    const i = getProductIndex(productId, productsRef.current);
    if (i >= 0) {
      let newProducts = [...productsRef.current];
      newProducts[i] = { ...newProducts[i], swappedProduct: null };
      productsRef.current = newProducts;
      setProductsState(newProducts);
      triggerLifeCycleEvent(PRODUCTS_CHANGED, newProducts);
    }
  };

  const addProduct = (prevProductId, newProductId) => {
    const i = getProductIndex(prevProductId, productsRef.current);
    if (prevProductId === newProductId) {
      let updatedProduct = [...productsRef.current];
      updatedProduct[i] = { ...updatedProduct[i], removed: false };
      productsRef.current = updatedProduct;
      setProductsState(updatedProduct);
      triggerLifeCycleEvent(PRODUCTS_CHANGED, updatedProduct);
    } else {
      updateProductRef.current = {
        index: i,
        product: null
      };
      setProductIds([newProductId]);
    }
  };

  const removeProduct = (productId) => {
    const i = getProductIndex(productId, productsRef.current);

    if (i !== -1) {
      let newProducts = [...productsRef.current];

      const { swappedProduct, ...newProduct } = newProducts[i] || {};
      newProducts[i] = { ...newProduct, removed: true };
      productsRef.current = newProducts;
      triggerLifeCycleEvent(PRODUCTS_CHANGED, productsRef.current);
    }
  };

  useEffect(() => {
    if (!listenToProductsChange) {
      return;
    }
    onLifeCycleEvent(PRODUCTS_CHANGED, ({ output }) => {
      setProductsState(output);
      if (productsRef?.current !== output) {
        productsRef.current = output;
      }
    });
  }, [listenToProductsChange]);

  const { data: productData } = useDataModel('product', {
    variables: {
      itemId: bundleId,
      storeId
    },
    skip: !bundleId
  });

  const bundleProductIds = productData?.product?.bundleSpecificationDetails?.components;

  const { data, loading, error: productsError } = useDataModel('products', {
    variables: {
      itemIds: productIds || bundleProductIds?.map(({ defaultProductId }) => defaultProductId)
    },
    skip: !productIds && !bundleProductIds?.map(({ defaultProductId }) => defaultProductId)
  });

  const updatedProduct = getUpdatedProduct(productData?.product);
  const updatedProducts = getUpdatedProducts(updatedProduct, (data?.products || []));

  useEffect(() => {
    if (data) {
      let newProductsData = productsRef.current.length > 0 ? [...productsRef.current] : updatedProducts;
      if (updateProductRef?.current !== null) {
        const newProduct = {
          ...updatedProducts?.[0],
          swappedProduct: updateProductRef?.current?.product
        };
        newProductsData[updateProductRef?.current.index] = newProduct;
        triggerLifeCycleEvent(PRODUCTS_CHANGED, newProductsData);
        updateProductRef.current = null;
      }
      productsRef.current = newProductsData;
    }
  }, [data]);

  return {
    itemId: bundleId,
    product: updatedProduct,
    products: productsState || updatedProducts || [],
    loading,
    error: false,
    productsError,
    swapProducts,
    swapBack,
    clearSwap,
    addProduct,
    removeProduct
  };
};