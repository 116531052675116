import { useState, useEffect } from 'react';
import {
  updatePriceComponent,
  getBundleSummaryCalculations
} from '../utils/product-bundle-utils';

const useBundleSummaryCalculations = (
  itemId, adjustedPricing, minAdvertisedPriceExists, deliveryData, quantityLimit, priceUnitOfMeasure
) => {
  const [calculations, setCalculations] = useState(null);

  useEffect(() => {
    if (adjustedPricing) {
      const bundleSummaryCalculations = getBundleSummaryCalculations(adjustedPricing, (deliveryData?.deliveryFee || 0));

      updatePriceComponent(
        bundleSummaryCalculations?.finalDiscountedPriceWithDeliveryFee,
        bundleSummaryCalculations?.totalDollarOff,
        bundleSummaryCalculations?.totalPercentOff,
        minAdvertisedPriceExists,
        bundleSummaryCalculations?.bundleSummaryPrice,
        itemId,
        quantityLimit,
        priceUnitOfMeasure
      );
      setCalculations(bundleSummaryCalculations);
    }
  }, [adjustedPricing, deliveryData, itemId]);

  return calculations;
};

export default useBundleSummaryCalculations;
